.login-form {
    border: 1px solid rgb(179, 179, 179);
    width: 40%;
    margin-top: 30px;
    padding: 20px;
    box-shadow: 1px 1px 10px gray;
    padding-bottom: 40px;
    z-index: 9999;
    background-color: white;
    position: relative;
}

.login-form button {
    margin-top: 20px;
    border: none;
    padding: 10px 20px 10px 20px;
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #30b4f6;
}

.login-form .icon-input {
    margin-top: 8px;
}

.notification {
    position: absolute;
    left: 50%;
    background-color: rgb(109, 24, 24);
    color: white;
    padding: 40px;
    display: none;
    z-index: 9999;
}

.blur-layer {
    background-color: rgb(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}