.scheduled-calls-menu {
    display: block;
    position: fixed;
    right: 0;
    width: 20%;
    height: 100%;
    top: 0;
    overflow: auto;
    border-left: 1px solid rgb(215, 215, 215);
    transition: 0.2s;
    box-shadow: 1px 1px 10px 2px gray
}

.content {
    margin-right: 18%;
    margin-top: 60px;
    width: 75%;
    overflow: auto;
}

.scheduled-calls-menu h5 {
    margin-top: 90px;
}

.calls-container {
    overflow-y: scroll;
}

.scheduled-call-tile {
    margin-top: 2px;
    background-color: #f6f6f6;
    padding: 20px;
    cursor: pointer;
}

.call-fab {
    position: fixed;
    bottom: 20px;
    right: 25%;
    border-radius: 100%;
    background-color: rgb(12, 12, 55);
    padding: 20px;
    cursor: pointer;
}

.call-fab:hover {
    background-color: rgb(27, 27, 124);
}

.scheduled-call-tile:hover {
    background-color: #cfcccc;
}

.scheduled-call-tile .selected-call {
    background-color: #cfcccc;
}

.call-form-modal .active-type {
    background-color: #1976d2;
    color: white;
}

.call-form-modal .active-type:hover {
    background-color: #114d88;
    color: white;
}