.vertical-nav {
    height: 70px;
    background-color: var(--primary-color);
    z-index: 999;
    position: sticky;
    top: 0;
    box-shadow: 1px 1px 15px 1px gray;
}

.dash-content {
    margin-left: 120px;
}

.logout-btn {
    position: absolute;
    right: 0; 
    margin-top: 20px; 
    margin-right: 30px;
    color: white;
    transition: 0.5s;
}

.logout-btn:hover {
    color: navy;
    cursor: pointer;
    transition: 0.5s;
}