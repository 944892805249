.icon-input {
    position:relative;
  }
  
.icon-input .icon {
    height: 1.5rem;
    width: 2.5rem;
    padding: 4px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    transform: translateY(-50%);
  }
  
.icon-input input {
    height: 50px;
    width: 50%;
    box-sizing:border-box;
    padding-left: 1.5rem;
    padding: 15px;
    padding-left: 34px;
  }