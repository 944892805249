.profile-tile {
    padding: 20px;
    background-color: #f6f6f6;
}

.profile-tile .profile-phone-numbers {
    font-size: 15px;
    font-weight: 600;
    margin-top: 40px;
    width: 90%;
    display: inline-block;
}

.profile-phone-numbers .num-icon {
    vertical-align: bottom;
}

.profile-phone-numbers .calling-num {
    display: inline;
    float: left
}

.profile-phone-numbers .whatsapp-num {
    display: inline;
    float: right;
}

.profile-data-tile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    border-top: 1px solid rgb(195, 195, 195);
}

.profile-data {
    margin: auto;
}

.risk-label {
    background-color: red;
    color: white;
    padding: 4px;
    font-size: 10px;
    font-weight: bold;
}