.nav-expand-fab {
    position: fixed;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: rgb(90 98 130);
    padding: 5px;
    z-index: 999;
    color: white;
    border: 2px solid white;
    margin-top: 100px;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s;
}

.nav-expand-fab:hover {
    background-color: #001b75;
    transition: 0.2s;
}