.users-col {
    display: flex;
    flex-direction: row;
}

.user-tile {
    border: 1px solid gray;
    padding: 50px 50px 20px 50px;
    background-color: rgb(247, 247, 247);
    transition: 0.2s;
    cursor: pointer;
    border-radius: 4px;
    margin: auto;
}

.user-tile:hover {
    box-shadow: 5px 2px 20px 1px gray;
    transition: 0.2s;
}

.search-in-users {
    padding: 5px
}

.search-submit {
    margin-top: 10px;
}