
.navbar-body {
    display: block;
    float: left;
    width: 100px;
    height: 100vh;
    background-color: #0095DE;
    overflow: auto;
    position: fixed;
    transition: 0.2s;
    z-index: 999;
}

.expanded {
    transition: 0.2s;
    width: 220px;
}

.nav-item {
    margin-top: 40px;
    padding: 20px;
    color: white;
    text-align: left;    
}

.nav-item-selected {
    border-left: 3px solid rgb(90 98 130);
}

.nav-item span {
    position: absolute;
    margin-left: 36px;
    font-family: 'Outfit', sans-serif;
}

.nav-item:hover {
    background-color: rgb(90 98 130);
    color: black;
}

.nav-item span {
    display: inline;
}